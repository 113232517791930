




































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import DxDropDownBox from "devextreme-vue/drop-down-box";
import DxList from "devextreme-vue/list";
import {
  DxSelection,
  DxPaging,
  DxFilterRow,
  DxScrolling,
} from "devextreme-vue/data-grid";
@Component({
  components: {
    DxDropDownBox,
    DxSelection,
    DxPaging,
    DxFilterRow,
    DxScrolling,
    DxList,
  },
})
export default class DropDownMultipleSelectionList extends Vue {
  @PropSync("data_source", { default: [] }) mis_datos!: any[];
  @PropSync("selectedItems", { default: [] }) selectedItemKeys!: any[];
  @Prop({ default: "nombre" }) displayExpr!: string;
  @Prop({ default: "id" }) valueExpr!: string;
  @Prop({ default: "Seleccione un valor" }) placeholder_text!: string;

  //public mis_datos: any[] = [];

  //public selectedItemKeys: any[] = [0, 1, 2, 3, 4, 12];
  created() {
    /*for (let index = 0; index < 100; index++) {
      this.mis_datos.push({ id: index, Name: "Data " + index });
    }*/
  }

  select_data(data: any) {
    this.selectedItemKeys = data;
  }
}
